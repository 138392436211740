<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            hide-default-footer
            :items-per-page="50"
        >
            <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
            ></v-progress-linear>
            <template v-slot:body="{ items }">
                <tbody>
                  <tr v-if="items.length === 0">
                    <td class="text-center p-2" :colspan="headers.length">No results</td>
                  </tr>
                    <tr v-for="item in items" :key="item.uuid">
                        <td>
                            <v-chip
                                color="secondary"
                                label
                                text-color="primary"
                                x-small
                            >
                                {{ item.type }}
                            </v-chip>
                        </td>
                        <td>{{ item.created_at | utc_to_local_datetime }}</td>
                        <td>{{ item.conversionData.created_at | utc_to_local_datetime }}</td>
                        <td>{{ item.conversionData.ip }}</td>
                      <td>
                        <v-chip
                           v-if="item.conversionData.user_agent.device"
                           color="secondary"
                           label
                           text-color="primary"
                           x-small
                        >
                          {{ item.conversionData.user_agent.device }}
                        </v-chip>
                        <br/>
                        <v-chip
                           v-if="item.conversionData.user_agent.operating_system"
                           color="secondary"
                           label
                           text-color="primary"
                           x-small
                        >
                          {{ item.conversionData.user_agent.operating_system }}
                        </v-chip>
                      </td>
                      <td>
                        <router-link
                           v-if="item.conversionData.business"
                           :title="item.conversionData.business.display_name"
                           :to="{
                                    name: 'business',
                                    params: { id: item.conversionData.business.uuid }
                                }"
                        >
                          {{ item.conversionData.business.display_name }}
                        </router-link>
                      </td>
                      <td>{{ item.conversionData.unique_id | truncate(8) }}</td>
                      <td>{{ item.conversionData.value | truncate(8) }}</td>
                      <td>{{ item.conversionData.currency | truncate(8) }}</td>
                      <td>{{ item.conversionData.promotion_code | truncate(8) }}</td>
                      <td>
                        <v-btn icon color="primary" outlined @click="selectedItem = item">
                          <v-icon small>fal fa-eye</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-pagination
            v-if="pagination"
            v-model="currPage"
            class="my-2"
            :length="total"
            :total-visible="7"
            @input="handlePageChange"
        ></v-pagination>

      <v-dialog v-if="selectedItem" v-model="showDialog" max-width="800px">
        <v-card>
          <v-card-title></v-card-title>
          <v-card-text>
            <div class="title">Log</div>
            <div class="grey lighten-3 overflow-auto rounded p-2">
              <pre>{{ JSON.stringify(JSON.parse(selectedItem.body), null, 2) }}</pre>
            </div>

            <div class="title mt-3">Request</div>
            <div class="grey lighten-3 overflow-auto rounded p-2">
              <pre>{{ JSON.stringify(selectedItem.request, null, 2) }}</pre>
            </div>

            <div class="title mt-3">Conversion data</div>
            <div class="grey lighten-3 overflow-auto rounded p-2">
              <pre>{{ JSON.stringify(selectedConversionData, null, 2) }}</pre>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>

<style scoped>
    td {
        white-space: nowrap;
    }
</style>

<script>
    import local_numbers from "@/helpers/filters/local_numbers";
    import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
    import truncate from "@/helpers/filters/truncate";
    import date_format from "@/helpers/filters/date_format";

    export default {
        filters: {
            local_numbers,
            utc_to_local_datetime,
            date_format,
            truncate
        },
        props: {
            items: {
                type: Array,
                required: false,
                default: () => {
                    return [];
                }
            },
            page: {
                type: Number
            },
            total: {
                type: Number
            },
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            pagination: {
                type: Boolean,
                required: false,
                default: false
            },
            hasLess: {
                type: Boolean,
                required: false,
                default: false
            },
            hasMore: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                headers: [],
                currPage: 1,
                selectedItem: null
            };
        },
      computed: {
        showDialog: {
          get() {
            return !! this.selectedItem;
          },
          set() {
            this.selectedItem = null;
          }
        },
        selectedConversionData() {
          if(this.selectedItem) {
            let data = this.cloneDeep(this.selectedItem.conversionData);
            delete this.selectedItem.conversionData.params;
            return data;
          }

          return "";
        }
      },
        methods: {
            handlePageChange(value) {
                this.$emit("changePage", value);
            }
        },
        watch: {
            items() {
                this.currPage = this.page;
            },
        },
        mounted() {
            this.headers = [
                { text: "Type", value: "type", sortable: false },
                { text: "Logged at", value: "created_at", sortable: false },
                { text: "Conversion at", sortable: false },
                { text: "IP", sortable: false },
                { text: "Device", sortable: false },
                { text: "Business", value: "conversionData.business.display_name", sortable: false },
                { text: "Unique ID", sortable: false },
                { text: "Amount", sortable: false },
                { text: "Currency", sortable: false },
                { text: "Promotion code", sortable: false },
                { text: "", sortable: false },
            ];
        },
    }
</script>

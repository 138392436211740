<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Conversion logs</div>
          <span class="subtitle-1 light-grey">
            All hits to our tracking code.

            <a href="https://www.notion.so/makeinfluence/Troubleshooting-9dd0852ec2e445bfa3aa82a8c8618759" target="_blank">
              Read more about trousbleshooting
          </a>
          </span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field
           v-model="searchTmp"
           label="Keywords"
           style="max-width: 300px;"
        ></v-text-field>
        <v-btn color="primary" @click="filters.search = searchTmp; filters.page = 1;">Search</v-btn>
        <br/>

        <div class="text-right total-shown">
          {{ items.length }} out of {{ totalAmount | local_numbers }}
        </div>
        <conversion-log-data-table
           :items="items"
           :loading="loading"
           pagination
           :has-less="filters.page > 1"
           :has-more="hasMore"
           :total="total"
           :page="filters.page"
           @changePage="_getConversionLogs($event)"
        ></conversion-log-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import local_numbers from "@/helpers/filters/local_numbers";
import ConversionLogDataTable from "../data-tables/ConversionLogDataTable";

export default {
  components: {
    ConversionLogDataTable,
  },
  filters: { local_numbers },
  mixins: [search_filters_url],
  data() {
    return {
      filters: {
        search: "",
        page: 1,
        limit: 50
      },
      searchTmp: "",
      loading: false,
      totalAmount: null,
      items: [],
      total: null,
      hasMore: true,
    }
  },
  watch: {
    filters: {
      handler: function () {
        this.setUrlSearchFilters(this.filters);
      },
      deep: true,
    },
    'filters.search': {
      handler: function () {
        // reset
        this.items = [];
        this.loading = false;
        this.hasMore = true;
        this.filters.page = 1;
        this._getConversionLogs();
        },
      deep: true,
    },
  },
  methods: {
    ...mapActions('core', ['getConversionLogs']),
    _getConversionLogs(page = 1) {
      if (this.loading) {
        return;
      }

      this.items = [];
      this.loading = true;

      this.filters.page = page;
      const filters = this.cloneDeep(this.filters);

      this.getConversionLogs(filters).then(response => {
        this.items = response.data;
        this.filters.page = response.currentPage;
        this.filters.limit = response.limit;

        // If no more items
        if (response.data.length !== filters.limit) {
          this.hasMore = false;
        }

        this.loading = false;
        this.total = response.lastPage;
        this.totalAmount = response.total;
      });
    },
  },
  created() {
    let filters = this.getUrlSearchFilters();

    if(Object.keys(filters).length > 0) {
      this.filters = filters;
      this.searchTmp = this.filters.search;
    } else {
      this._getConversionLogs();
    }
  }
}
</script>